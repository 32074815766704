import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DetailSignatureRequestModel } from '../models/detail-signature-request.model';
import { SignedPdfResponse } from '../models/signed-pdf-response';
import {environment} from "../../environments/environment";

import {ElementoAFirmar, ResultG } from '../models/firma-documento-store.model';
@Injectable({
  providedIn: 'root'
})
export class FirmaService {

  constructor(
    private http: HttpClient
  ) {}


  generateSignedPdf(data: any): Observable<SignedPdfResponse> {
    const headers: HttpHeaders = new HttpHeaders()
      .set('Access-Control-Allow-Origin', '*');
     
   
//"/firma-api/api/v1/genl/firma/electronica/detalle/create/signed/pdf"
//"http://localhost:8088/api/v1/genl/firma/electronica/detalle/create/signed/pdf"
    return this.http.post<SignedPdfResponse>(
      environment.apiUrlFirma+'api/signature/signature-document-return',
      data,
      { headers,  responseType: 'json' }
    );
  }
  generateSignedPdfActo(data: any): Observable<SignedPdfResponse> {
    const headers: HttpHeaders = new HttpHeaders()
      .set('Access-Control-Allow-Origin', '*');
     
   
//"/firma-api/api/v1/genl/firma/electronica/detalle/create/signed/pdf"
//"http://localhost:8088/api/v1/genl/firma/electronica/detalle/create/signed/pdf"
    return this.http.post<SignedPdfResponse>(
      environment.apiUrlFirma+'api/signature/signature-acto',
      data,
      { headers,  responseType: 'json' }
    );
  }
  getdocument(iddoc: string){
    
    const headers: HttpHeaders = new HttpHeaders()
    .set('Access-Control-Allow-Origin', '*');
    
  const url = environment.apiUrlFirma+'api/signature/get-encode-document-base?idDocument=' + iddoc;
  return this.http.get<ResultG>(url ,{ headers, responseType: 'json'})
  }
 
  getActo(idAct: string){
    
    const headers: HttpHeaders = new HttpHeaders()
    .set('Access-Control-Allow-Origin', '*');
    
  const url = environment.apiUrlFirma+'api/signature/get-encode-document-base-act?idAct=' + idAct;
  return this.http.get<ResultG>(url ,{ headers, responseType: 'json'})
  }

  



  
}
