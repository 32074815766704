import { Injectable } from '@angular/core';
import { hextob64, KEYUTIL, KJUR, RSAKey, rstrtohex, X509 } from 'jsrsasign';

@Injectable({
  providedIn: 'root'
})
export class PkcsService {

  leerCer(cerBinString: string): X509 {
    let hex = rstrtohex(cerBinString);
    let pem = KJUR.asn1.ASN1Util.getPEMStringFromHex(hex, "CERTIFICATE");
    let x509 = new X509();
    x509.readCertPEM(pem);
    return x509;
  }

  leerKey(keyBinString: string, passcode: string): RSAKey { 
    let hex = rstrtohex(keyBinString);
    let pem = KJUR.asn1.ASN1Util.getPEMStringFromHex(hex, "ENCRYPTED PRIVATE KEY");
    let rsaKey: RSAKey = KEYUTIL.getKeyFromEncryptedPKCS8PEM(pem, passcode) as RSAKey;
    return rsaKey;
  }

  firmarB64(b64Hash: string, rsaKey: RSAKey) {
    let sig: KJUR.crypto.Signature = new KJUR.crypto.Signature({'alg': 'SHA256withRSA'});
    sig.init(rsaKey);
    console.log('string : '+ b64Hash )
    console.log('Firma la hace: ')
    console.log(sig.signString(b64Hash))
    return sig.signString(b64Hash);
  }

  firmarBinString(binString: string, rsaKey: RSAKey) {
    let sig: KJUR.crypto.Signature = new KJUR.crypto.Signature({'alg': 'SHA256withRSA'});
    sig.init(rsaKey);
    let hex: string = rstrtohex(binString);
    let md: KJUR.crypto.MessageDigest = new KJUR.crypto.MessageDigest({"alg": "sha256", "prov": "cryptojs"});
    let hashValueHex = md.digestHex(hex);
    let b64Hash = hextob64(hashValueHex)
    console.log('bin string: '+ binString)
    console.log('firma:')
    console.log(sig.signString(b64Hash))
    return sig.signString(b64Hash);
  }

  digestSha1(binString: string) {
    let hex: string = rstrtohex(binString);
    let md: KJUR.crypto.MessageDigest = new KJUR.crypto.MessageDigest({"alg": "sha1", "prov": "cryptojs"});
    let hashValueHex = md.digestHex(hex);
    return hextob64(hashValueHex);
  }

  constructor() { }

}
