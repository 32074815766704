<h2>Firma de documentos</h2>
<div class="container">
  <form (submit)="$event.preventDefault()" [formGroup]="formularioFirmarDocumento">
    <div class="row">
      <label for="userId">RFC del usuario</label>
      <input type="text"
             formControlName="userId"
             id="userId"
             (change)="onUserChange($event)">
    </div>
    


    <div class="row">
      <label for="certificado">Certificado</label>
      <input type="file"
             id="certificado"
             [accept]="['.cer']"
             (change)="onCerSeleccionado($event)">
    </div>

    <div class="row">
      <label for="key">Llave (key)</label>
      <input type="file"
             id="key"
             [accept]="['.key']"
             (change)="onKeySeleccionado($event)">
    </div>

    <div class="row">
      <label for="password">Contraseña</label>
      <input type="password" formControlName="password" id="password">
    </div>

    <div class="">
      <p>Errores</p>
      <div class="error" *ngIf="validacionOcsp !== true">
        <div>{{ validacionOcsp }}</div>
      </div>
      <div class="error" *ngIf="errores">
        <ng-container *ngFor="let error of errores">
          <div>{{ error }}</div>
        </ng-container>
      </div>
    </div>

    <div class="row">
<!--      <button (click)="formularioFirmarDocumento.reset()">Limpiar Formulario</button>-->
      <button (click)="Firmas()">Firmar documento</button>
    </div>
  </form>
</div>
