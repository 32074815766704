import { Component } from '@angular/core';
import {
	FirmaDocumentosStoreService
} from '../services/firma-documentos-store.service';
import { CanActivate, Router } from '@angular/router';
import { hextob64, RSAKey, rstrtohex, X509, zulutodate } from "jsrsasign";
import { PkcsService } from "../services/pkcs.service";
import { DatePipe } from "@angular/common";
import { FirmaService } from "../services/firma.service";
import { DetailSignatureRequestModel } from "../models/detail-signature-request.model";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
	ArchivoAFirmar,
	ElementoAFirmar,
	ResultadoFirma,
	ResultadoValidacionCer,
	ResultadoValidacionKey
} from '../models/firma-documento-store.model';
import { SignerModel } from "../models/signer.model";
import { environment } from '../../environments/environment';

@Component({
	selector: 'FirmarDocumento',
	templateUrl: './firma-document.component.html',
	styleUrls: ['./firma-document.component.css']
})
export class FirmaDocumentComponent {

	//  
	private key: RSAKey | null = null;
	files: File[] = [];

	IDDocumento: string = '0';
	IdAct: string = '0';
	PDF: string = '';
	token: string = '';
	// Archivo .cer
	cerFile: File | null = null;

	// Archivo .key
	keyFile: File | null = null;

	// Almacenar el certificado como un binario
	cerBinString: string | null = null;

	// Objeto que contiene los datos del cer
	infoCer!: ResultadoValidacionCer;
	// RFC dentro del cer
	rfcCertificado: string = "";
	// Fecha de inicio de la validez del certificado
	inicioCertificado: Date | string = "";
	// Fecha de fin de la validez del certificado
	finCertificado: Date | string = "";
	// Subject dentro del cer
	subject: string | null = null;
	// Número de serie dentro del cer
	noSerie: string | null = null;

	// RFC del usuario
	rfc: string = "";

	// Hashes de los archivos a firmar
	hashes: ElementoAFirmar[] = [];

	// Lista de errores encontrados en el certificado
	errores: string[] = [];

	// Validación de OCSP, resultado que se obtiene del back
	validacionOcsp: string | true = "";

	// Contenido del .cer
	infoSigner!: ResultadoValidacionCer;

	// Formulario
	formularioFirmarDocumento: FormGroup;

	// Controlar si estamos tratando de subir el formulario
	submitted: boolean = false;

	// Resultado de leer los ceers y obtener su informacion
	result!: ResultadoFirma;

	constructor(
		// Servicio conectado al back para generar los archivos firmados y guardarlos
		private firmaService: FirmaService,
		// Servicio para obtener datos del documento a firmar
		private firmaDocumentosStoreService: FirmaDocumentosStoreService,
		// Servicio para obtener información del certificado
		private pkcsService: PkcsService,
		private router: Router,
		// Crear formularios
		private fb: FormBuilder
	) {
		// Inicializar formulario
		this.formularioFirmarDocumento = fb.group({
			userId: ["", [Validators.required]],
			documento: [this.getDocument()],
			certificado: [null, [Validators.required]],
			key: [null, [Validators.required]],
			password: ["", [Validators.required]]
		});
	}

	getDocument() {
		// Verificamos que tengamos los archivos que queremos firmar
		const filesQuery = window.location.search;

		console.log('Obtener el documento.');

		const params = new URLSearchParams(filesQuery);

		// let filename = params.get('iddocument') + '_SnFirma.pdf'
		let name = params.get('iddocument');

		// const url = "https://localhost:5001/api/signature/get-signature-document?iddocument=" + name;
		const url = environment.apiUrlFirma + "api/signature/get-signature-document?iddocument=" + name;

		console.log('Documento: ' + url);

		return url;
	}

	// Actualizar los datos al cambiar el usuario
	onUserChange(event: Event) {
		let element = event.currentTarget as HTMLInputElement;
		this.rfc = element.value;
	}

	// Handler para actualizar el formulario con el documento a firmar
	onDocumentoSeleccionado(event: Event) {
		// Obtener el elemento del HTML
		let fileEvent = event.currentTarget as HTMLInputElement;

		// Obtener lista de archivos del elemento
		let fileList: FileList | null = fileEvent.files;

		// Si no hay archivos, terminar la ejecución de esta función
		if (!fileEvent || !fileEvent.files || fileEvent.files.length == 0) {
			return;
		}

		// Si no hay archivos en el elemento, poner un null en el formulario y salir
		if (!fileList) {
			this.formularioFirmarDocumento.patchValue({
				documento: null
			});

			return;
		}

		this.files = [fileList[0]];

		// Si hay archivos, tomar el primer archivo
		this.formularioFirmarDocumento.patchValue({
			documento: fileList[0]
		});
	}

	// Handler para cuando se selecciona un cer
	onCerSeleccionado(event: Event) {
		// Limpiar los errores
		this.errores = [];

		// Leer elemento del html
		let fileEvent = event.target as HTMLInputElement;

		// Si no hay archivos, terminar la ejecución de esta función
		if (!fileEvent || !fileEvent.files || fileEvent.files.length == 0) {
			return;
		}

		// Leer cer del elemento
		this.cerFile = fileEvent.files[0];

		// Si no hay archivo terminar la función
		if (!this.cerFile) {
			return;
		}

		// Actualizar el certificado en el formulario
		this.formularioFirmarDocumento.patchValue({
			certificado: this.cerFile
		})

		// Preparar objeto para leer el cer y obtener información
		let cerReader: FileReader = new FileReader();

		// Pasos a ejecutar al leer el archivo .cer
		cerReader.onload = () => {
			// Obtenemos el binario del archivo cer
			this.cerBinString = cerReader.result as string;

			// Ejecutamos la lectura del cer para obtener los datos dentro del cer
			this.firmaDocumentosStoreService
				.setCer(this.cerBinString, this.rfc)
				.then((resultado) => {
					// Actualizar los datos obtenidos del cer
					this.infoCer = resultado;
					this.rfcCertificado = resultado.rfc ?? "";
					this.inicioCertificado = resultado.inicio ?? "";
					this.finCertificado = resultado.fin ?? "";
					this.noSerie = resultado.noSerie ?? "";
					this.subject = resultado.subject ?? "";
					this.errores = [...new Set(resultado.errores)];

					// Validar en el backend que el cer es válido
					this.firmaDocumentosStoreService.validateOCSP().subscribe({
						next: (data: any) => {
							if (data.codigoEstatusOcsp !== 0) {
								this.validacionOcsp = "No se puede verificar el certificado en este momento";
								return;
							}

							switch (data.codigoEstatusRevocacion) {
								case 0:
									this.validacionOcsp = true;
									return;
								case 1:
									this.validacionOcsp = "El certificado ha sido revocado";
									return;
								case 2:
									this.validacionOcsp = "Ocurrió un error inesperado durante la verificación";
									return;
								case 5:
									this.validacionOcsp = "El certificado ha sido revocado";
									return;
							}
						},
						error: (err) => {
							// Cuando el certificado no es verdadero se manda un 500 como response
							if (err.status == 500) {
								this.validacionOcsp = "El certificado no es válido";
							}
						}
					});
				});
		};

		// Mandamos a leer el archivo
		cerReader.readAsBinaryString(this.cerFile as File);

		return;
	}

	// Leer cuando se sube un archivo .key
	onKeySeleccionado(event: Event) {
		// Leer elemento html
		let fileEvent = event.target as HTMLInputElement;

		// Si no hay archivos seleccionados, terminar ejecución
		if (!fileEvent || !fileEvent.files || fileEvent.files.length == 0) {
			return;
		}

		// Actualizar archivo
		this.keyFile = fileEvent.files[0];

		// Si no hay archivo, terminar el proceso
		if (!this.keyFile) {
			return;
		}

		// Actualizar archivo en el formulario
		this.formularioFirmarDocumento.patchValue({
			key: this.keyFile
		})

		return;
	}


	Firmas() {
		const filesQuery = window.location.search;
		const params = new URLSearchParams(filesQuery)
		const name = params.get('iddocument')
		const idAct = params.get('idAct')
		const token = params.get('token')
		if (idAct) {
			this.firmaService.getActo(idAct ? idAct.toString() : '').subscribe(res => {
				const file = new File([
					new Blob([res.pdf])
				], idAct ? idAct.toString() : '');
				this.token = token ? token.toString() : ''
				this.files = [file]
				this.IdAct = idAct ? idAct.toString() : ''
				this.firmarDocumento()
			})
		} else {
			this.firmaService.getdocument(name ? name.toString() : '').subscribe(res => {
				const file = new File([
					new Blob([res.pdf])
				], name ? name.toString() : '');
				this.token = token ? token.toString() : ''
				this.files = [file]
				this.IDDocumento = name ? name.toString() : ''
				this.firmarDocumento()
			})
		}
	}

	// Tomar los datos del cer necesarios para firmar el archivo
	firmarDocumento() {
		// Limpiamos los errores
		this.errores = []


		// Cambiamos el estado del submit
		this.submitted = true;

		// Validar si faltan campos en el formulario
		if (this.formularioFirmarDocumento.invalid) {
			console.log('Faltan campos en el formulario');
			return;
		}

		// Resultado de validar el OCSP
		if (this.validacionOcsp !== true) {
			console.log('Validación OCSP fallida');
			return;
		}

		// Verificamos que tengamos los archivos que queremos firmar

		if (this.files.length !== 0) {

			let file = this.files[0]
			this.firmaDocumentosStoreService.agregarArchivo(file.name, file)

		}

		// Leer el archivo key
		let readKeyPromise = new Promise<void>((resolve, reject) => {
			// Preparamos la lectura del archivo key
			let keyReader: FileReader = new FileReader();

			keyReader.onload = () => {
				// Actualizamos los datos para poder firmar
				const resultado: ResultadoValidacionKey = this.firmaDocumentosStoreService.setKey(
					keyReader.result as string,
					this.formularioFirmarDocumento.controls["password"].value
				);

				if (resultado.errores && resultado.errores.length > 0) {
					reject(resultado);
				}

				resolve();
			};

			// Mandar a leer el archivo
			keyReader.readAsBinaryString(this.keyFile as File);
			console.log(this.formularioFirmarDocumento)
			console.log('aa')
		}).then(() => {
			// Mandar a firmar el archivo con los datos obtenidos
			console.log('vamos  a firmar')
			return this.firmaDocumentosStoreService.firmar();
		}).then((elementosConFirma) => {
			// Emitir el resultado con los datos necesarios para guardar el archivo en el back
			this.result = {
				certificadoB64: this.firmaDocumentosStoreService.certificadoB64,
				elementosConFirma: elementosConFirma,
				infoCer: this.infoCer
			}

			// Informacion para hacer la firma
			this.infoSigner = this.result.infoCer;


			// Iterar sobre los elementos a firmar, en este caso solo es un archivo
			this.result.elementosConFirma.forEach((fir: any) => {
				// Obtener los archivos para mandarlos al back y guardarlo
				const file2Sign: ArchivoAFirmar = fir.elementoAFirmar.elemento;

				if ("archivo" in file2Sign) {
					// Crear body del request
					const formData: FormData = this.createRequestUploadFile(file2Sign.archivo);

					// Usar el servicio para enviar el archivo original al gestor de archivos
					// Obtenemos el id generado por el archivo
					const originalDocumentId = this.IDDocumento;

					// Obtenemos el hash del archivo que queremos firmar
					this.getHashFile(file2Sign.archivo).then(hashFile => {
						// const hashFile = res;
						const signer = this.createSigner(fir.firma);

						// Array de firmantes
						const _signers: SignerModel[] = [];
						_signers.push(signer);

						// Datos a enviar al back para asociar con el archivo subido
						if (this.IdAct == "0" && this.IDDocumento != "0") {
							const data = {
								signers: _signers,
								documentId: originalDocumentId,
								hashOriginalFile: hashFile,
								userId: this.formularioFirmarDocumento.controls['userId'].value,
								token: this.token
							};
							let token = this.token
							// Pedimos al back que genere un archivo con los datos obtenidos del cer
							this.firmaService.generateSignedPdf(data).subscribe(res => {


								alert(res.message)
								// this.router.navigate(['/Fin']);

								this.cleanData();
							}, error => alert(error.error.message));
						} else {
							const datas = {
								signers: _signers,
								idAct: this.IdAct,
								hashOriginalFile: hashFile,
								userId: this.formularioFirmarDocumento.controls['userId'].value,
								token: this.token
							};
							let token = this.token
							// Pedimos al back que genere un archivo con los datos obtenidos del cer
							this.firmaService.generateSignedPdfActo(datas).subscribe(res => {


								alert(res.message)
								// this.router.navigate(['/Fin']);

								this.cleanData();
							}, error => alert(error.error.message));
						}
					});

				}
			})
		}).catch((resultado) => {
			// Obtener los errores de analizar los archivos
			if (resultado.errores)
				this.errores.push(...resultado.errores);
			else
				this.errores.push(resultado.message);
		});
	}

	// Crear el formData que vamos a enviar al back
	createRequestUploadFile(file: File): FormData {
		const formData: FormData = new FormData();

		const myNewFile = new File([file], Date.now() + file.name, { type: file.type });

		// El mismo archivo firmado
		formData.append("file", myNewFile);
		formData.append("signFlowId", '0');
		// El id del usuario que esta firmando
		formData.append("userId", this.formularioFirmarDocumento.controls['userId'].value);

		return formData;
	}

	// Obtener el hash del documento que queremos firmar
	getHashFile(file: File): Promise<string> {

		return new Promise<string>((resolve, reject) => {
			const reader = new FileReader();
			reader.onload = () => {
				let binString = reader.result as string;
				let hash = this.pkcsService.digestSha1(binString);
				resolve(hash);
			};
			reader.readAsBinaryString(file);
		})
	}

	// Crear un objeto con datos del firmante
	private createSigner(_sign: string): SignerModel {
		const date = new Date();
		const datepipe: DatePipe = new DatePipe('en-US')
		let formattedDate = datepipe.transform(date, 'dd-MM-yyyy HH:mm:ss')
		const signer: SignerModel = {
			rfc: this.infoSigner.rfc ?? "",
			name: this.infoSigner.subject ?? "",
			order: 0,
			noCertificate: this.infoSigner.noSerie ?? "",
			rubric: false,
			sign: _sign,
			signDate: formattedDate ?? ""
		};

		return signer;
	}

	// Crear los datos para a enviar al back para relacionar el archivo firmado con un firmante
	createSignatureRequest(
		signer: SignerModel,
		cerValue: string,
		_idDocFirmado: string,
		_idDocOriginal: string,
		_userId: string,
		_folioValidacion: string
	): DetailSignatureRequestModel {
		const _signature: DetailSignatureRequestModel = {
			firma: signer.sign,
			certificado: cerValue,
			idDocFirmado: _idDocFirmado,
			fechaFirma: signer.signDate,
			idDocOriginal: _idDocOriginal,
			idFlujo: 0,
			idUsuarioFirmante: _userId,
			nombreFirmante: signer.name,
			noSerieCer: signer.noCertificate,
			ordenRubrica: 0,
			rubrica: false,
			rfcFirmante: signer.rfc,
			folioValidacion: _folioValidacion
		};
		return _signature;
	}

	// Limpiar los campos del formulario
	private cleanData(): void {
		this.infoSigner = { valido: false, errores: [] };

		this.formularioFirmarDocumento.patchValue({
			userId: "",
			documento: null,
			certificado: null,
			key: null,
			password: ""
		})
	}

	// Descargar el pdf que contiene la firma
	downloadFile(_data: any, filename: string): void {
		let file = _data;
		var newBlob = new Blob([file]);

		// For other browsers:
		// Create a link pointing to the ObjectURL containing the blob.
		const data = window.URL.createObjectURL(newBlob);

		var link = document.createElement('a');
		link.href = data;
		link.download = "signed_" + filename;
		// this is necessary as link.click() does not work on the latest firefox
		link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

		setTimeout(function () {
			// For Firefox it is necessary to delay revoking the ObjectURL
			window.URL.revokeObjectURL(data);
			link.remove();
		}, 100);
	}
}

