import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { FinComponent } from './fin/fin.component';
import { FirmaDocumentComponent } from './firma-documento/firma-document.component';
import {HttpClientModule} from "@angular/common/http";
import {ReactiveFormsModule} from "@angular/forms";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Routes, RouterModule } from '@angular/router';
export const rutas: Routes  = [
  {path: 'firmar', component: FirmaDocumentComponent},
  {path: 'fin/fin', component: FirmaDocumentComponent},
];

@NgModule({
  declarations: [
    AppComponent,
    FirmaDocumentComponent,
    FinComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    ReactiveFormsModule,
    RouterModule.forRoot(rutas),
    NgbModule
  ],
  exports: [RouterModule],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
  

 }
