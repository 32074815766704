import { Injectable } from '@angular/core';
import { hextob64, RSAKey, rstrtohex, X509, zulutodate } from "jsrsasign";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import { PkcsService } from './pkcs.service';
import IdentityResponse = jsrsasign.IdentityResponse;
import {ArchivoAFirmar, ElementoAFirmar, ElementoConFirma, HashAFirmar, ResultadoValidacionCer, ResultadoValidacionKey } from '../models/firma-documento-store.model';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class FirmaDocumentosStoreService {

  private elementosAFirmar: ElementoAFirmar[] = [];
  private certificado: X509 | null = null;
  private key: RSAKey | null = null;
  private _certificadoB64: string = '';

  constructor(
    private pkcsService: PkcsService,
    private httpClient: HttpClient
  ) {}

  get certificadoB64() {
    return this._certificadoB64;
  }

  agregarArchivo(nombre: string, archivo: File): void {
    console.log('firma:')
    console.log(archivo)
    let elementoAFirmar: ElementoAFirmar = {
      nombre: nombre,
      elemento: { archivo: archivo },
    };
    this.elementosAFirmar.push(elementoAFirmar);
  }

  agregarHash(nombre: string, hashB64: string): void {
    let elementoAFirmar: ElementoAFirmar = {
      nombre: nombre,
      elemento: { hashB64: hashB64 },
    };
    this.elementosAFirmar.push(elementoAFirmar);
  }

  setCer(cerBinString: string, rfc: string): Promise<ResultadoValidacionCer> {
    const result: ResultadoValidacionCer = { valido: true, errores: [] };
    this._certificadoB64 = hextob64(rstrtohex(cerBinString));
    try {
      this.certificado = this.pkcsService.leerCer(cerBinString);
    } catch (error) {
      if (error instanceof Error) {
        result.errores?.push(error.message);
      }
      result.valido = false;
      return Promise.resolve(result);
    }
    const notBefore: Date = zulutodate(this.certificado.getNotBefore());
    const noSerie: string = this.certificado.getSerialNumberHex();
    const subject: IdentityResponse = this.certificado.getSubject();
    const subjectName: string = subject && subject.array && subject.array[0] && subject.array[0][0] ? subject.array[0][0].value : "";

    result.inicio = notBefore;
    result.subject = subjectName;
    result.noSerie = noSerie;
  
    const notAfter: Date = zulutodate(this.certificado.getNotAfter());
    result.fin = notAfter;
    const now: Number = Date.now();
    if (notBefore.getTime() > now || notAfter.getTime() < now) {
      result.valido = false;
      result.errores?.push("Certificado no vigente");
    }
    let rfcCertificado = this.certificado
      .getSubject()["array"].filter((x) => {
      return x[0].type === "uniqueIdentifier";
    })[0][0].value;
    result.rfc = rfcCertificado;
    console.log(rfcCertificado)
    console.log(result.rfc)
    console.log(rfc)
    if (rfcCertificado !== rfc) { 
      result.valido = false;
      result.errores?.push("RFC del certificado es distinto al RFC del usuario que se encuentra firmado");
    }
    return Promise.resolve(result);
  }

  setKey(keyBinString: string, passcode: string): ResultadoValidacionKey {
    const resultadoValidacionKey: ResultadoValidacionKey = {valido: true, errores: []};

    try {
      this.key = this.pkcsService.leerKey(keyBinString, passcode);
    } catch(error) {
      resultadoValidacionKey.valido = false;
      if (error !instanceof Error) {
        if (error.message.includes('malformed format: SEQUENCE(0).items != 2')) {
          resultadoValidacionKey.errores?.push("El archivo key no es válido");
        } else if(error.message.includes('malformed plain PKCS8 private key(code:001)')) {
          resultadoValidacionKey.errores?.push("Password incorrecto");
        } else {
          resultadoValidacionKey.errores?.push("Error al leer el archivo .key");
        }
        return resultadoValidacionKey;
      }
      resultadoValidacionKey.errores?.push("Error al leer el archivo .key");
      return resultadoValidacionKey;
    }
    return resultadoValidacionKey;
  }

  firmar(): Promise<ElementoConFirma[]> {
    if (this.key == null) {
      throw new Error('La llave privada no ha sido configurada.')
    }
    if (this.certificado == null) {
      throw new Error('El certificado no ha sido configurado.')
    }
    const resultadoFirmas: Promise<ElementoConFirma>[] = [];
  
    this.elementosAFirmar.forEach(e => {
      if ('archivo' in e.elemento) {
      console.log('hola')
      console.log(e.elemento)
        resultadoFirmas.push(this.firmaArchivoPromise(e));
      } else if ('hashB64' in e.elemento) {
       
        resultadoFirmas.push(this.firmaHashB64Promise(e));
      } else {
        throw new Error("Elemento a firmar no soportado.");
      }
    });
    return Promise.all(resultadoFirmas);
  }

  public firmaArchivo(firma: string ){
    return new Promise ((resolve) => {
      console.log('lalal')
      let firmado = this.pkcsService.firmarBinString(firma, this.key as RSAKey);
      console.log(resolve)
      resolve( {sign: firmado});
    }
    );
    
  }
 public firmaArchivoPromise(elementoAFirmar: ElementoAFirmar): Promise<ElementoConFirma> {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = () => {
        let binString = reader.result as string;
       
        let firma = this.pkcsService.firmarBinString(binString, this.key as RSAKey);
      
        resolve({elementoAFirmar: elementoAFirmar, firma: firma});
      };
      let archivoAFirmar: ArchivoAFirmar = elementoAFirmar.elemento as ArchivoAFirmar;
     
      reader.readAsBinaryString(archivoAFirmar.archivo);
    })
  }

  public firmaHashB64Promise(elementoAFirmar: ElementoAFirmar): Promise<ElementoConFirma> {
    let hashAFirmar: HashAFirmar = elementoAFirmar.elemento as HashAFirmar;
    let firma = this.pkcsService.firmarB64(hashAFirmar.hashB64, this.key as RSAKey);
    console.log('firmahash')
    console.log(firma)
    return Promise.resolve({elementoAFirmar: elementoAFirmar, firma: firma});
  }

  // Validar el CER para saber si es valido para firmar
  // Regresa true si el CER es valido, en caso de que exista algun error, se regresa un string
  //  del error para ser mostrado en el dialog
  validateOCSP() {
   
    const headers: HttpHeaders = new HttpHeaders()
      .set('Access-Control-Allow-Origin', '*');
 // alert(JSON.stringify(environment.apiUrlOCSP))
    return this.httpClient.post<any>( 
      //'http://localhost:8083/api/v1/genl/proxy/ocsp/consultar',
        environment.apiUrlOCSP +'api/v1/genl/proxy/ocsp/consultar',
      {
        certBase64:
          "-----BEGIN CERTIFICATE-----\n" +
          this._certificadoB64 +
          "\n-----END CERTIFICATE-----"

      },
      {
        headers
      });
  }
}
